
<!-- User / Visitor chooser -->
<div class="row" *ngIf="hasAdminRole">
    <div class="col-12">
        <label class="form-label">{{ 'services.craning.booking.form.debtorType' | translate }}*</label>

        <div>
            <div class="btn-group" role="group">
                <input class="btn-check" type="radio"
                       [formControl]="debtorType"
                       [value]="DebtorType.User">
                <label class="btn btn-toggle btn-toggle-left h-auto"
                       [for]="debtorType"
                       [ngClass]="{ active: debtorType.value === DebtorType.User }"
                       translate="services.craning.booking.form.debtorTypes.user"
                       (click)="changeDebtorType(DebtorType.User)"
                       (keyup)="changeDebtorType(DebtorType.User)"></label>

                <input class="btn-check" type="radio"
                       [formControl]="debtorType"
                       [value]="DebtorType.Visitor">
                <label class="btn btn-toggle btn-toggle-right h-auto"
                       [for]="debtorType"
                       [ngClass]="{ active: debtorType.value === DebtorType.Visitor }"
                       translate="services.craning.booking.form.debtorTypes.visitor"
                       (click)="changeDebtorType(DebtorType.Visitor)"
                       (keyup)="changeDebtorType(DebtorType.Visitor)"></label>
            </div>
        </div>

        <app-form-error [control]="debtorType"></app-form-error>
    </div>
</div>

<!-- User Search field -->
<div class="row mt-4" *ngIf="hasAdminRole && debtorType.value === DebtorType.User && debtorType.enabled">
    <div class="col-12">
        <app-search-user #searchUserComponent ngDefaultControl
                         [userContact]="userContact"
                         (selectedUserEvent)="setSelectedUser($event)"></app-search-user>
    </div>
</div>

<!-- User Summary -->
<app-user-visitor-summary *ngIf="debtorType.value === DebtorType.User && userContact"
                          [hasAdminRole]="hasAdminRole"
                          [visitor]="userContact"></app-user-visitor-summary>

<!-- Visitor form -->
<div class="row" *ngIf="debtorType.value === DebtorType.Visitor">
    <div class="col-12">
        <app-user-data-form [contactForm]="visitorContactForm"
                            [visitorContactDto]="visitor"></app-user-data-form>
    </div>
</div>
