import {Component, Input, OnInit} from '@angular/core';
import {
    DocumentDto, EquipmentContractService,
    EquipmentInterventionService,
    EquipmentService, InformationService, SurveyService
} from "../../../../_services/configuration-services";
import {DocumentManagementTypeEnum} from "../document-management.component";
import {faDownload} from '@fortawesome/free-solid-svg-icons';
import * as fileSaver from 'file-saver';

@Component({
    selector: 'app-document-view',
    templateUrl: './document-view.component.html'
})
export class DocumentViewComponent implements OnInit {
    documents: DocumentDto[] = [];

    @Input() id: string | null;
    @Input() documentManagementType: DocumentManagementTypeEnum;

    private readonly documentManagementUtilities: DocumentManagementUtilities = {
        fetch: null,
        download: null,
    };
    icons = {
        download: faDownload
    };

    constructor(private readonly equipmentService: EquipmentService,
                private readonly interventionService: EquipmentInterventionService,
                private readonly informationService: InformationService,
                private readonly contractService: EquipmentContractService,
                private readonly surveyService: SurveyService) {
    }

    ngOnInit(): void {
        this.setupComponent();
        this.fetchData();
    }


    public fetchData(): void {
        this.documentManagementUtilities.fetch(this.id).pipe().subscribe(data => {
                this.documents = data;
            }
        );
    }

    downloadItem(document): void {
        this.documentManagementUtilities.download(this.id, document.id).pipe().subscribe(response => {
            fileSaver.saveAs(response.data, response.fileName);
        });
    }

    private setupComponent(): void {
        switch (this.documentManagementType) {
            case DocumentManagementTypeEnum.Contract:
                this.documentManagementUtilities.fetch = (id) => {
                    return this.contractService.getDocuments(id);
                };
                this.documentManagementUtilities.download = (id, docId) => this.contractService.downloadDocument(id, docId);
                break;
            case DocumentManagementTypeEnum.Equipment:
                this.documentManagementUtilities.fetch = (id) => this.equipmentService.getDocuments(id);
                this.documentManagementUtilities.download = (id, docId) => this.equipmentService.downloadDocument(id, docId);
                break;
            case DocumentManagementTypeEnum.Intervention:
                this.documentManagementUtilities.fetch = (id) => this.interventionService.getDocuments(id);
                this.documentManagementUtilities.download = (id, docId) => this.interventionService.downloadDocument(id, docId);
                break;
            case DocumentManagementTypeEnum.Information:
                this.documentManagementUtilities.fetch = (id) => this.informationService.getDocuments(id);
                this.documentManagementUtilities.download = (id, docId) => this.informationService.downloadDocument(id, docId);
                break;
            case DocumentManagementTypeEnum.Survey:
                this.documentManagementUtilities.fetch = (id) => this.surveyService.getDocuments(id);
                this.documentManagementUtilities.download = (id, docId) => this.surveyService.downloadDocument(id, docId);
                break;
        }
    }
}

interface DocumentManagementUtilities {
    fetch: any,
    download: any
}
