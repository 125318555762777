import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {RfidCardDto, RfidCardService, RfidCardState} from '../../../_services/configuration-services';
import {FormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'app-rfid-card-modal',
    templateUrl: './rfid-card-modal.component.html'
})
export class RfidCardModalComponent implements OnInit {

    @Input() rfidCard: RfidCardDto | null;
    @Input() cardNumberDisabled = false;
    @Input() uidDisabled = false;

    rfidForm: UntypedFormGroup;
    cardStates = Object.keys(RfidCardState);

    constructor(public activeModal: NgbActiveModal,
                private readonly rfidCardService: RfidCardService,
                private readonly fb: FormBuilder) {
    }

    ngOnInit(): void {
        this.rfidForm = this.fb.group({
                cardNumber: [{value: null, disabled: this.cardNumberDisabled}, Validators.required],
                uid: [{value: null, disabled: this.uidDisabled}, Validators.required],
                cardState: [{ value: null }, Validators.required],
            },
            {updateOn: 'change'}
        );

        if(this.rfidCard){
            this.rfidForm.patchValue(this.rfidCard);
        }

        if (this.rfidCard?.userId) {
            this.cardStates = this.cardStates.filter(c => c !== RfidCardState.Unassigned)
        } else {
            this.cardStates = this.cardStates.filter(c => c !== RfidCardState.Assigned)
        }

        this.cardState.setValue(this.rfidCard?.cardState);
    }

    async addOrUpdate(): Promise<void> {
        const rfiCard = new RfidCardDto();
        rfiCard.cardNumber = this.rfidForm.controls['cardNumber'].value.trim();
        rfiCard.uid = this.rfidForm.controls['uid'].value.trim();
        rfiCard.userId = this.rfidCard?.userId;

        rfiCard.cardState = this.cardState.value;

        if (!this.rfidCard?.cardNumber) {
            await firstValueFrom(this.rfidCardService.createRfidCard(rfiCard));
            this.activeModal.close('success');
        } else {
            await firstValueFrom(this.rfidCardService.updateRfidCard(this.rfidCard.cardNumber, rfiCard));
            this.activeModal.close('success');
        }
    }

    get cardNumber(): UntypedFormControl {
        return this.rfidForm.get('cardNumber') as UntypedFormControl;
    }

    get uid(): UntypedFormControl {
        return this.rfidForm.get('uid') as UntypedFormControl;
    }

    get cardState(): UntypedFormControl {
        return this.rfidForm.get('cardState') as UntypedFormControl;
    }
}
