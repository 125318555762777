import {Component, Input, OnChanges,SimpleChanges} from '@angular/core';
import {RfidCardLightDto, RfidCardService} from '../../../_services/configuration-services';
import {firstValueFrom} from 'rxjs';

@Component({
  selector: 'app-user-cards-summary',
  templateUrl: './user-cards-summary.component.html'
})
export class UserCardsSummaryComponent implements OnChanges {

    @Input() userId: string = null!;

    userCards: RfidCardLightDto[] = [];

    constructor(private readonly rfidCardService: RfidCardService) {
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes['userId']?.currentValue) {
            this.userCards = await firstValueFrom(this.rfidCardService.getRfidCardsForUser(this.userId));
        }
    }
}
