import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {faDownload, faTrash} from '@fortawesome/free-solid-svg-icons';
import {
    EquipmentContractService,
    EquipmentInterventionService,
    EquipmentService,
    InformationService,
    SurveyService
} from '../../../_services/configuration-services';
import {ConfirmModalService} from '../confirm-modal/confirm-modal.component';
import {NotificationsService} from '../../notifications.service';
import {DocumentUploadComponent} from './document-upload-component/document-upload.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as fileSaver from 'file-saver';

export enum DocumentManagementTypeEnum {
    Equipment = 1,
    Contract = 2,
    Intervention = 3,
    Information = 4,
    Survey = 5
}

@Component({
    selector: 'document-management-component',
    templateUrl: './document-management.component.html'
})
export class DocumentManagementComponent implements OnInit, OnDestroy {

    @Input() id: string | null;
    @Input() documentManagementType: DocumentManagementTypeEnum;
    @Input() useH5Title = false; // true if the title should be h5 (subtitle)

    @Output() fileListChangeEvent = new EventEmitter<void>();

    // icons
    faDownload = faDownload;
    faTrash = faTrash;

    documents: Document[] = [];
    private readonly documentManagementUtilities: DocumentManagementUtilities = {
        fetch: null,
        download: null,
        delete: null,
        upload: null
    };

    constructor(private readonly equipmentService: EquipmentService,
                private readonly interventionService: EquipmentInterventionService,
                private readonly notificationService: NotificationsService,
                private readonly informationService: InformationService,
                private readonly surveyService: SurveyService,
                private readonly modalService: NgbModal,
                private readonly contractService: EquipmentContractService,
                private readonly confirmModalService: ConfirmModalService,
    ) {
    }

    ngOnInit(): void {
        this.setupComponent();
        this.fetchData();
    }

    ngOnDestroy(): void {
        this.modalService.dismissAll('destroy');
    }

    public fetchData(): void {
        this.documentManagementUtilities.fetch(this.id).pipe().subscribe(data => {
                this.documents = data.map(d => d as Document);
            }
        );
    }

    openAddDocumentToEquipmentModal() {
        let modal = this.modalService.open(DocumentUploadComponent, {centered: true});
        modal.componentInstance.id = this.id;
        modal.componentInstance.uploadAction = this.documentManagementUtilities.upload;
        modal.result.then(() => {
            this.fetchData();
            this.fileListChangeEvent.emit();
        }, () => { /* catch the rejection */ });
    }

    deleteItem(document) {
        this.confirmModalService.confirm({titleKey: 'common.confirmModal.title.delete'}).then(result => {
            if (result === ConfirmModalService.yes) {
                this.documentManagementUtilities.delete(this.id, document.id).pipe().subscribe(_ => {
                    this.notificationService.success({title: 'common.document.notifications.deleteSuccess'});
                    this.fetchData();
                });
                this.fileListChangeEvent.emit();
            }
        });
    }

    downloadItem(document) {
        this.documentManagementUtilities.download(this.id, document.id).pipe().subscribe(response => {
            fileSaver.saveAs(response.data, response.fileName);
        });
    }

    private setupComponent() {
        switch (this.documentManagementType) {
            case DocumentManagementTypeEnum.Contract:
                this.documentManagementUtilities.fetch = (id) => this.contractService.getDocuments(id);
                this.documentManagementUtilities.download = (id, docId) => this.contractService.downloadDocument(id, docId);
                this.documentManagementUtilities.delete = (id, docId) => this.contractService.deleteDocument(id, docId);
                this.documentManagementUtilities.upload = (id, title, file) => this.contractService.addDocumentToContract(id, title, file);
                break;
            case DocumentManagementTypeEnum.Equipment:
                this.documentManagementUtilities.fetch = (id) => this.equipmentService.getDocuments(id);
                this.documentManagementUtilities.download = (id, docId) => this.equipmentService.downloadDocument(id, docId);
                this.documentManagementUtilities.delete = (id, docId) => this.equipmentService.deleteDocument(id, docId);
                this.documentManagementUtilities.upload = (id, title, file) => this.equipmentService.addDocumentToEquipment(id, title, file);
                break;
            case DocumentManagementTypeEnum.Intervention:
                this.documentManagementUtilities.fetch = (id) => this.interventionService.getDocuments(id);
                this.documentManagementUtilities.download = (id, docId) => this.interventionService.downloadDocument(id, docId);
                this.documentManagementUtilities.delete = (id, docId) => this.interventionService.deleteDocument(id, docId);
                this.documentManagementUtilities.upload = (id, title, file) => this.interventionService.addDocumentToIntervention(id, title, file);
                break;
            case DocumentManagementTypeEnum.Information:
                this.documentManagementUtilities.fetch = (id) => this.informationService.getDocuments(id);
                this.documentManagementUtilities.download = (id, docId) => this.informationService.downloadDocument(id, docId);
                this.documentManagementUtilities.delete = (id, docId) => this.informationService.deleteDocument(id, docId);
                this.documentManagementUtilities.upload = (id, title, file) => this.informationService.addDocumentToInformation(id, title, file);
                break;
            case DocumentManagementTypeEnum.Survey:
                this.documentManagementUtilities.fetch = (id) => this.surveyService.getDocuments(id);
                this.documentManagementUtilities.download = (id, docId) => this.surveyService.downloadDocument(id, docId);
                this.documentManagementUtilities.delete = (id, docId) => this.surveyService.deleteDocument(id, docId);
                this.documentManagementUtilities.upload = (id, title, file) => this.surveyService.addDocumentToSurvey(id, title, file);
                break;
        }
    }
}

interface DocumentManagementUtilities {
    fetch: any,
    delete: any
    download: any
    upload: any
}

interface Document {
    id: string,
    title: string
}

