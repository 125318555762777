import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {Day, DayOfWeek} from '../../../_services/configuration-services';
import {FormUtils} from '../../form-utils';

@Component({
  selector: 'app-days-picker',
  templateUrl: './days-picker.component.html'
})
export class DaysPickerComponent implements OnInit, OnDestroy {

    @Input() form: FormGroup = null!;
    @Input() defaultActivation = false;

    @Input() initialDays: Day[] = [];

    daysOfWeek = Object.keys(Day);

    ngOnInit(): void {
        this.form.addControl('days', new FormArray([], [FormUtils.requiredOneTrueValidator]));

        this.daysOfWeek.forEach(() => {
            this.days.push(new FormControl<boolean>(this.defaultActivation));
        });

        if (this.initialDays.length > 0) {
            const selectedDays = this.daysOfWeek
                .map(day => this.initialDays.includes(day as Day));
            this.days.setValue(selectedDays);
        }
    }

    ngOnDestroy(): void {
        this.form.removeControl('days')
    }

    getSelectedDays(): Day[] {
        let selectedDays: Day[] = [];
        for (let i = 0; i < this.days.value.length; i++) {
            if (this.days.value[i]) {
                selectedDays.push(this.getDayByPosition(i));
            }
        }
        return selectedDays;
    }

    setSelectedDay(days: DayOfWeek): void {
        let selectedDays: boolean[] = [];

        for (const day of this.daysOfWeek) {
            selectedDays.push(days.includes(day));
        }

        this.days.setValue(selectedDays);
    }

    private getDayByPosition(i: number): Day {
        switch (i) {
            case 0:
                return Day.Monday;
            case 1:
                return Day.Tuesday;
            case 2:
                return Day.Wednesday;
            case 3:
                return Day.Thursday;
            case 4:
                return Day.Friday;
            case 5:
                return Day.Saturday;
            case 6:
                return Day.Sunday;
            default:
                return null;
        }
    }

    get days(): FormArray {
        return this.form.get('days') as FormArray;
    }
}
