import {Component, Directive, Injectable, TemplateRef} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-confirm-modal',
    templateUrl: './confirm-modal.component.html',
    styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {

    defaultMessageKey = 'common.confirmModal.message.default';

    options: ConfirmModalOptions = {
        titleKey: 'common.confirmModal.title',
        messageKey: null,
        infoKey: null,
        infoList: null,
        yesKey: 'common.yes',
        noKey: 'common.no'
    };

    constructor(private state: ConfirmModalState) {
        this.options.titleKey = state.options?.titleKey ?? 'common.confirmModal.title';
        this.options.messageKey = state.options?.messageKey ?? null;
        this.options.infoKey = state.options?.infoKey ?? null;
        this.options.infoList = state.options?.infoList ?? null;
        this.options.yesKey = state.options?.yesKey ?? 'common.yes';
        this.options.noKey = state.options?.noKey ?? 'common.no';
    }

    close(): void {
        this.state.modal.close(ConfirmModalService.no);
    }

    yes(): void {
        this.state.modal.close(ConfirmModalService.yes);
    }

    no(): void {
        this.state.modal.close(ConfirmModalService.no);
    }
}

interface ConfirmModalOptions {
    titleKey?: string;
    messageKey?: string;
    infoKey?: string;
    infoList?: string[];
    yesKey?: string;
    noKey?: string;
}

@Injectable()
export class ConfirmModalState {
    options?: ConfirmModalOptions; // The last options passed ConfirmService.confirm()
    modal: NgbModalRef; // The last opened confirmation modal
    template: TemplateRef<any>; // The template containing the confirmation modal component
}

/**
 * A confirmation service, allowing to open a confirmation modal from anywhere and get back a promise.
 */
@Injectable()
export class ConfirmModalService {

    public static readonly yes = 'yes';
    public static readonly  no = 'no';

    constructor(private readonly modalService: NgbModal,
                private readonly state: ConfirmModalState) {}

    /**
     * Opens a confirmation modal
     * @param options the options for the modal (title and message)
     * @returns Promise<any> a promise that is fulfilled when the user chooses to confirm, and rejected when
     * the user chooses not to confirm, or closes the modal
     */
    confirm(options?: ConfirmModalOptions): Promise<any> {
        this.state.options = options;
        this.state.modal = this.modalService.open(this.state.template, {centered: true});
        return this.state.modal.result
            .catch(() => { /* catch the rejection */ });
    }
}

/**
 * Directive allowing to get a reference to the template containing the confirmation modal component,
 * and to store it into the internal confirm state service. Somewhere in the view, there must be
 *
 * ```
 * <template confirm>
 *   <confirm-modal-component></confirm-modal-component>
 * </template>
 * ```
 *
 * in order to register the confirm template to the internal confirm state
 */
@Directive({
    selector: '[appConfirmModal]'
})
export class ConfirmModalTemplateDirective {
    constructor(confirmTemplate: TemplateRef<any>, state: ConfirmModalState) {
        state.template = confirmTemplate;
    }
}
