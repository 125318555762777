<div class="modal-header">

    <h4 class="modal-title">{{ !!rfidCard.cardNumber ? ('rfidCards.modal.modifyTitle' | translate) : 'rfidCards.modal.addTitle' | translate}}</h4>

    <button class="btn-close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')">
    </button>
</div>

<div class="modal-body">
    <form name="rfidCardFrom" [formGroup]="rfidForm">
        <div class="row">

            <!-- Card Number -->
            <div class="col-6">
                <label class="form-label" for="cardNumberInput">{{ 'rfidCards.table.cardNumber' | translate }}*</label>
                <input class="form-control" id="cardNumberInput" maxlength="25" name="userNameInput"
                       placeholder="{{'rfidCards.placeholder.cardNumber' | translate}}"
                       formControlName="cardNumber" required>
                <app-form-error [control]="cardNumber"></app-form-error>
            </div>

            <!-- UID -->
            <div class="col-6">
                <label class="form-label" for="uidInput">{{ 'rfidCards.table.uid' | translate }}*</label>
                <input class="form-control" id="uidInput" maxlength="25"
                       name="uidInput"
                       placeholder="{{'rfidCards.placeholder.uid'  | translate}}"
                       formControlName="uid" required>
                <app-form-error [control]="uid"></app-form-error>
            </div>

            <div class="col-6">
                <label class="form-label" for="cardState" translate="rfidCards.state"></label>
                <select class="form-control form-select" id="cardState" name="cardState"
                        [formControl]="cardState">
                    <option *ngFor="let state of cardStates"
                            [ngValue]="state">{{'rfidCards.status.' + state.toLowerCase() | translate}}</option>
                </select>
            </div>
        </div>
    </form>
</div>

<div class="modal-footer">
    <button class="btn btn-outline-secondary" type="submit"
            (click)="activeModal.close()" translate="common.cancel">
    </button>
    <button class="btn btn-primary"
            [disabled]="rfidForm.invalid"
            (click)="addOrUpdate()">
        {{ !!rfidCard.cardNumber ? ('common.modify' | translate) : ('common.create' | translate)}}
    </button>
</div>
