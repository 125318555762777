<div>
    <div class="modal-header">
        <h4 class="modal-title">{{ 'common.sortable.title' | translate }}</h4>

        <button class="btn-close" aria-label="Close"
                (click)="activeModal.dismiss('Cross click')">
        </button>
    </div>

    <!-- Sortable data -->
    <div class="modal-body">
        <ngx-sortable [items]="sortableData"
                      [showHeader]="false"
                      [listStyle]="sortableListStyle"
                      (listSorted)="listOrderChanged($event)">
            <ng-template let-item="item">
                <div class="sortable-list-item">
                    <fa-icon [icon]="icons.sort"></fa-icon>
                    <span>{{ item['displayText' + this.crtLang.toUpperCase()] }}</span>
                </div>
            </ng-template>
        </ngx-sortable>
    </div>

    <div class="modal-footer">
        <button class="btn btn-outline-secondary"
                (click)="activeModal.close()"
                translate="common.cancel">
        </button>
        <button type="submit" class="btn btn-primary"
                (click)="saveSort()"
                [disabled]="!edited"
                translate="common.save">
        </button>
    </div>
</div>
