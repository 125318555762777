
<!-- Item selection -->
<div class="row" *ngIf="items?.length > 1">

    <div class="col-12">
        <label class="form-label" for="item-select" translate="bookings.calendar.selectObject"></label>
        <select id="item-select" class="form-control form-select"
                (change)="onItemSelected($event)">
            <option [value]="null" [ngValue]="null">-</option>
            <option *ngFor="let item of items;"
                    [value]="item.id"
                    [ngValue]="item.id">{{ item['name' + crtLang.toUpperCase()] }}</option>
        </select>
    </div>
</div>

<!-- Selected item display -->
<div class="row mt-2" *ngIf="selectedItem">
    <div class="col-12">
        <h4>{{ selectedItem['name' + crtLang.toUpperCase()] }}</h4>

        <div class="text-pre-wrap" *ngIf="selectedItem['description' + crtLang.toUpperCase()]">
            {{ selectedItem['description' + crtLang.toUpperCase()] }}
        </div>
    </div>
</div>
