import localeFr from '@angular/common/locales/fr';
import localeDe from '@angular/common/locales/de';
import localeIt from '@angular/common/locales/it';
import {
    MSAL_GUARD_CONFIG,
    MSAL_INSTANCE,
    MSAL_INTERCEPTOR_CONFIG,
    MsalBroadcastService,
    MsalGuard,
    MsalInterceptor,
    MsalModule,
    MsalRedirectComponent,
    MsalService
} from '@azure/msal-angular';
import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {ServicesComponent} from './services/services.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateUtils} from './_shared/translate-utils';
import {UsersComponent} from './users/users.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {CashierTransactionComponent} from './ewallet/transactions/cashier-transaction/cashier-transaction.component';
import {ViewServiceComponent} from './services/view-service/view-service.component';
import {ManageServiceComponent} from './services/manage-service/manage-service.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {
    AvailabilityRangeModalComponent
} from './services/manage-service/availability-range/availability-range-modal/availability-range-modal.component';
import {ItemModalComponent} from './services/manage-service/item/item-modal/item-modal.component';
import {HasAnyRoleDirective} from './_shared/has-any-role';
import {TariffModalComponent} from './services/manage-service/tariff/tariff-modal/tariff-modal.component';
import {GlobalErrorHandler} from './_shared/error-handler';
import {LoggerModule, NgxLoggerLevel} from 'ngx-logger';
import {ToastrModule} from 'ngx-toastr';
import {AppHttpInterceptor} from './_shared/http-interceptor';
import {UserManagementComponent} from './users/user-management/user-management.component';
import {ZXingScannerModule} from '@zxing/ngx-scanner';
import {UserRfidCardModalComponent} from './users/user-management/user-rfid-card-modal/user-rfid-card-modal.component';
import {HydrowashModalComponent} from './services/manage-service/hydrowash/hydrowash-modal/hydrowash-modal.component';
import {
    ActivateHydrowashModalComponent
} from './services/view-service/activate-hydrowash-modal/activate-hydrowash-modal.component';
import {CguComponent} from './cgu/cgu.component';
import {MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory} from './auth-config';
import {
    PurchaseServiceModalComponent
} from './services/view-service/purchase-service-modal/purchase-service-modal.component';
import {TransactionsComponent} from './transactions/transactions.component';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {ManageMapModalComponent} from './services/manage-service/manage-map/manage-map-modal/manage-map-modal.component';
import {DatePipe, DecimalPipe, registerLocaleData} from '@angular/common';
import {FullCalendarModule} from '@fullcalendar/angular';
import {CurrencyFormat} from './_shared/_components/pipe/currency-format.pipe';
import {NumberFormatPipe} from './_shared/number-format';
import {EditMooringPlaceComponent} from './services/manage-service/edit-mooring-place/edit-mooring-place.component';
import {RfidCardsComponent} from './rfid-cards/rfid-cards.component';
import {RfidCardModalComponent} from './_shared/_components/rfid-card-modal/rfid-card-modal.component';
import {
    DoorGroupModalComponent
} from './services/manage-service/door-group/door-group-modal/door-group-modal.component';
import {
    AssignServiceMeasuringPointModalComponent
} from './services/manage-service/assign-service-measuring-point-modal/assign-service-measuring-point-modal.component';
import {
    CockpitEnergyUserComponent
} from './services/view-service/energy/cockpit-energy-user/cockpit-energy-user.component';
import {NgxDaterangepickerMd} from 'ngx-daterangepicker-material';
import {
    CockpitEnergyStateComponent
} from './services/view-service/energy/cockpit-energy-state/cockpit-energy-state.component';
import {
    CockpitEnergyConsumptionComponent
} from './services/view-service/energy/cockpit-energy-consumption/cockpit-energy-consumption.component';
import {
    ConsumptionBarChartComponent
} from './services/view-service/energy/consumption-bar-chart/consumption-bar-chart.component';
import {ApplicationLoaderComponent} from './_shared/_components/loader/application-loader.component';
import {SearchUserComponent} from './_shared/_components/search-user/search-user.component';
import {MooringPlaceComponent} from './services/view-service/mooring-place/mooring-place.component';
import {
    CalendarEventPopoverComponent
} from './_shared/_components/calendar/event-popover/calendar-event-popover.component';
import {
    ViewMooringPlaceComponent
} from './services/view-service/mooring-place/view-mooring-place/view-mooring-place.component';
import {
    MooringPlaceViewModalComponent
} from './_shared/_components/mooring-place-view-modal/mooring-place-view-modal.component';
import {
    SearchMeasuringPointComponent
} from './_shared/_components/search-measuring-point/search-measuring-point.component';
import {DateRangePickerComponent} from './_shared/_components/date-range-picker/date-range-picker.component';
import {CockpitBatteryComponent} from './_shared/_components/cockpit-battery/cockpit-battery.component';
import {EnergyComponent} from './services/view-service/energy/energy.component';
import {DayRangeFormat} from './_shared/_components/pipe/day-range-format.pipe';
import {AlarmComponent} from './alarms/alarm.component';
import {AlarmHistoryComponent} from './alarms/alarm-history/alarm-history.component';
import {AlarmDeviceManagementComponent} from './alarms/alarm-device-management/alarm-device-management.component';
import {AlarmManagementComponent} from './alarms/alarm-management/alarm-management.component';
import {AlertComponent} from './alerts/alert.component';
import {AlertManagementComponent} from './alerts/alert-management/alert-management.component';
import {AlertHistoryComponent} from './alerts/alert-history/alert-history.component';
import {AlertDeviceManagementComponent} from './alerts/alert-device-management/alert-device-management.component';
import {InfrastructureComponent} from './infrastructure/infrastructure.component';
import {OfficeHourManagementComponent} from './infrastructure/office-hour-management/office-hour-management.component';
import {HydrowashManagementComponent} from './services/manage-service/hydrowash/hydrowash-management.component';
import {ItemManagementComponent} from './services/manage-service/item/item-management.component';
import {PaymentTypeChooserComponent} from './_shared/_components/payment-type-chooser/payment-type-chooser.component';
import {WifiManagementComponent} from './services/manage-service/wifi/wifi-management/wifi-management.component';
import {WifiModalComponent} from './services/manage-service/wifi/wifi-management/wifi-modal/wifi-modal.component';
import {
    WifiSubscriptionListForAdminComponent
} from './services/view-service/wifi/wifi-subscription-list-for-admin/wifi-subscription-list-for-admin.component';
import {
    WifiSubscriptionListForUserComponent
} from './services/view-service/wifi/wifi-subscription-list-for-user/wifi-subscription-list-for-user.component';
import {TariffManagementComponent} from './services/manage-service/tariff/tariff-management.component';
import {UserAlarmManagementComponent} from './services/view-service/user-alarm/user-alarm-management.component';
import {UserAlarmModalComponent} from './services/view-service/user-alarm/user-alarm-modal/user-alarm-modal.component';
import {UserAlarmLogsComponent} from './services/view-service/user-alarm/user-alarm-logs/user-alarm-logs.component';
import {
    OfficeHourModalComponent
} from './infrastructure/office-hour-management/office-hour-modal/office-hour-modal.component';
import {
    OfficeHourExceptionModalComponent
} from './infrastructure/office-hour-management/office-hour-exception-modal/office-hour-exception-modal.component';
import {
    AlarmDeviceModalComponent
} from './alarms/alarm-device-management/alarm-device-modal/alarm-device-modal.component';
import {SearchIotRegisterComponent} from './_shared/_components/search-iot-register/search-iot-register.component';
import {
    AlertDeviceModalComponent
} from './alerts/alert-device-management/alert-device-modal/alert-device-modal.component';
import {
    CraningServiceManagementComponent
} from './services/manage-service/craning/craning-service/craning-service-management/craning-service-management.component';
import {
    CraningServiceManagementModalComponent
} from './services/manage-service/craning/craning-service/craning-service-management/craning-service-management-modal/craning-service-management-modal.component';
import {
    CraningServicePricingManagementModalComponent
} from './services/manage-service/craning/craning-service/craning-service-management/craning-service-pricing-management-modal/craning-service-pricing-management-modal.component';
import {
    CraningServiceQuestionManagementModalComponent
} from './services/manage-service/craning/craning-service/craning-service-management/craning-service-question-management-modal/craning-service-question-management-modal.component';
import {
    AlarmManagementModalComponent
} from './alarms/alarm-management/alarm-management-modal/alarm-management-modal.component';
import {FormErrorComponent} from './_shared/_components/form-error/form-error.component';
import {
    CraningBookingFormComponent
} from './services/view-service/craning/craning-booking/craning-booking-form/craning-booking-form.component';
import {CraningBookingComponent} from './services/view-service/craning/craning-booking/craning-booking.component';
import {
    CraningBookingSlotsGenerationModalComponent
} from './services/view-service/craning/craning-booking/craning-booking-slots-generation-modal/craning-booking-slots-generation-modal.component';
import {
    CraningBookingSlotsDeletionModalComponent
} from './services/view-service/craning/craning-booking/craning-booking-slots-deletion-modal/craning-booking-slots-deletion-modal.component';
import {
    CraningBookingCalendarComponent
} from './services/view-service/craning/craning-booking/craning-booking-calendar/craning-booking-calendar.component';
import {
    CraningBookingSlotManagementModalComponent
} from './services/view-service/craning/craning-booking/craning-booking-slot-management-modal/craning-booking-slot-management-modal.component';
import {DatePeriodPickerComponent} from './_shared/_components/date-period-picker/date-period-picker.component';
import {AvailabilityRangeComponent} from './services/manage-service/availability-range/availability-range.component';
import {DoorGroupComponent} from './services/manage-service/door-group/door-group.component';
import {
    CraningBookingFormConfirmationModalComponent
} from './services/view-service/craning/craning-booking/craning-booking-form/craning-booking-form-confirmation-modal/craning-booking-form-confirmation-modal.component';
import {CraningComponent} from './services/view-service/craning/craning.component';
import {CraningBillingComponent} from './services/view-service/craning/craning-billing/craning-billing.component';
import {
    CraningBookingPrintComponent
} from './services/view-service/craning/craning-booking/craning-booking-print/craning-booking-print.component';
import {
    CraningBookingFormBoatEditModalComponent
} from './services/view-service/craning/craning-booking/craning-booking-form/craning-booking-form-boat-edit-modal/craning-booking-form-boat-edit-modal.component';
import {
    ConfirmModalComponent,
    ConfirmModalService,
    ConfirmModalState,
    ConfirmModalTemplateDirective
} from './_shared/_components/confirm-modal/confirm-modal.component';
import {
    CraningBookingFormBillDetailsComponentComponent
} from './services/view-service/craning/craning-booking/craning-booking-form/craning-booking-form-bill-details-component/craning-booking-form-bill-details-component.component';
import {
    TransactionsImportModalComponent
} from './transactions/transactions-import-modal/transactions-import-modal.component';
import {AngularFireModule} from '@angular/fire/compat';
import {AngularFireMessagingModule} from '@angular/fire/compat/messaging';
import {AppConstants} from './app.constants';
import {WebcamManagementComponent} from './services/manage-service/webcam/webcam-management.component';
import {
    WebcamManagementModalComponent
} from './services/manage-service/webcam/webcam-management-modal/webcam-management-modal.component';
import {WebcamComponent} from './services/view-service/webcam/webcam.component';
import {SortableListModalComponent} from './_shared/_components/sortable-list-modal/sortable-list-modal.component';
import {RoleManagementComponent} from './_shared/_components/role-management/role-management.component';
import {TransactionBillingComponent} from './transactions/transaction-billing/transaction-billing.component';
import {TransactionCommonComponent} from './transactions/transaction-common/transaction-common.component';
import {UserDataFormComponent} from './_shared/_components/user-data-form/user-data-form.component';
import {
    DateTimePeriodPickerComponent
} from './_shared/_components/date-time-period-picker/date-time-period-picker.component';
import {UrlTextToLinkPipe} from './_shared/_components/pipe/url-text-to-link.pipe';
import {
    UserDeleteConfirmationModalComponent
} from './users/user-management/user-delete-confirmation-modal/user-delete-confirmation-modal.component';
import {
    MaintenanceContractViewComponent
} from './infrastructure/maintenance/maintenance-contract-view/maintenance-contract-view.component';
import {
    BillingStateModalComponent
} from './transactions/transaction-billing/billing-state-modal/billing-state-modal.component';
import {MaintenanceComponent} from './infrastructure/maintenance/maintenance.component';
import {ManageEquipmentComponent} from './infrastructure/maintenance/manage-equipment/manage-equipment.component';
import {
    MaintenanceCalendarComponent
} from './infrastructure/maintenance/maintenance-calendar/maintenance-calendar.component';
import {
    EquipmentEventModalComponent
} from './infrastructure/maintenance/equipment-event-modal/equipment-event-modal.component';
import {SearchEquipmentComponent} from './_shared/_components/search-equipment/search-equipment.component';
import {
    DocumentUploadComponent
} from './_shared/_components/document-management-component/document-upload-component/document-upload.component';
import {
    DocumentManagementComponent
} from './_shared/_components/document-management-component/document-management.component';
import {InterventionsComponent} from './infrastructure/maintenance/interventions/interventions.component';
import {
    ManageInterventionComponent
} from './infrastructure/maintenance/interventions/manage-intervention/manage-intervention.component';
import {
    ExportPottingerStatisticModalComponent
} from './services/view-service/pottinger/export-pottinger-statistic-modal/export-pottinger-statistic-modal.component';
import {InformationsComponent} from './communication-channel/informations/informations.component';
import {ContactComponent} from './communication-channel/contact/contact.component';
import {SurveysComponent} from './communication-channel/surveys/surveys.component';
import {CommunicationChannelComponent} from './communication-channel/communication-channel.component';
import {
    SurveysManagementComponent
} from './communication-channel/surveys/surveys-management/surveys-management.component';
import {
    ContactManagementComponent
} from './communication-channel/contact/contact-management/contact-management.component';
import {
    DocumentViewComponent
} from './_shared/_components/document-management-component/document-view/document-view.component';
import {
    InformationsManagementComponent
} from './communication-channel/informations/informations-management/informations-management.component';
import {ManageSurveyComponent} from './communication-channel/surveys/surveys-edition/manage-survey.component';
import {ViewInfoComponent} from './communication-channel/informations/view-info/view-info.component';
import {
    ActivateServiceModalComponent
} from './services/view-service/activate-service-modal/activate-service-modal.component';
import {
    MeasuringPointsCockpitComponent
} from './services/view-service/measuring-points-cockpit/measuring-points-cockpit.component';
import {QRCodeModule} from 'angularx-qrcode';
import {QrCodeViewComponent} from './services/manage-service/qr-code-view/qr-code-view.component';
import {
    PushNotificationModalComponent
} from './_shared/_components/push-notification-modal/push-notification-modal.component';
import {ViewSurveyComponent} from './communication-channel/surveys/view-survey/view-survey.component';
import {
    ManageInformationComponent
} from './communication-channel/informations/manage-information/manage-information.component';
import {WeatherCockpitComponent} from './services/view-service/weather-cockpit/weather-cockpit.component';
import {ForgetMeComponent} from './forget-me/forget-me.component';
import {
    PointOfSaleManagementComponent
} from './services/manage-service/point-of-sale/point-of-sale-management.component';
import {
    PointOfSaleManagementModalComponent
} from './services/manage-service/point-of-sale/point-of-sale-management-modal/point-of-sale-management-modal.component';
import {JoinPipe} from './_shared/_components/pipe/join.pipe';
import {
    KocoDumpsterManagementComponent
} from './services/manage-service/koco-dumpster/koco-dumpster-management.component';
import {
    KocoDumpsterManagementModalComponent
} from './services/manage-service/koco-dumpster/koco-dumpster-management-modal/koco-dumpster-management-modal.component';
import {KocoDumpsterComponent} from './services/view-service/koco-dumpster/koco-dumpster.component';
import {
    ActivateKocoDumpsterModalComponent
} from './services/view-service/koco-dumpster/activate-koco-dumpster-modal/activate-koco-dumpster-modal.component';
import {UserExportModalComponent} from './users/user-export-modal/user-export-modal.component';
import {EwalletsComponent} from './ewallet/ewallets.component';
import {ArrayMapPipe} from './_shared/_components/pipe/array-map.pipe';
import {EwalletViewComponent} from './ewallet/ewallet-view/ewallet-view.component';
import {StatisticsComponent} from './statistics/statistics.component';
import {TransactionFullSearchComponent} from './transactions/transaction-full-search/transaction-full-search.component';
import { ActivationManagementComponent } from './services/view-service/activation-management/activation-management.component';
import { ExtendActivationModalComponent } from './services/view-service/limited-duration/extend-activation-modal/extend-activation-modal.component';
import {CgvComponent} from "./cgv/cgv.component";
import { ReloadWalletModalComponent } from './ewallet/ewallet-view/reload-wallet-modal/reload-wallet-modal.component';
import { RfidCardsToInlineTextPipe } from './_shared/_components/pipe/rfid-cards-to-inline-text.pipe';
import {TimeFormatPipe} from './_shared/_components/pipe/time-format.pipe';
import {NgxSortableModule} from 'ngx-sortable';
import { ManageMapComponent } from './services/manage-service/manage-map/manage-map.component';
import {ShowCgvComponent} from './_shared/_components/show-cgv/show-cgv.component';
import { PublicLightingManagementComponent } from './services/manage-service/public-lighting/public-lighting-management.component';
import { LightZoneManagementModalComponent } from './services/manage-service/public-lighting/light-zone-management/light-zone-management-modal/light-zone-management-modal.component';
import { LightZoneManagementComponent } from './services/manage-service/public-lighting/light-zone-management/light-zone-management.component';
import { LightViewModalComponent } from './_shared/_components/light-view-modal/light-view-modal.component';
import { ManageMapContentComponent } from './services/manage-service/manage-map-content/manage-map-content.component';
import { LinkLightToExternalServiceModalComponent } from './services/manage-service/public-lighting/link-light-to-external-service-modal/link-light-to-external-service-modal.component';
import { LightZoneLightsManagementModalComponent } from './services/manage-service/public-lighting/light-zone-management/light-zone-lights-management-modal/light-zone-lights-management-modal.component';
import { PublicLightingComponent } from './services/view-service/public-lighting/public-lighting.component';
import { ViewLightsComponent } from './services/view-service/public-lighting/view-lights/view-lights.component';
import { ViewLightModalComponent } from './services/view-service/public-lighting/view-lights/view-light-modal/view-light-modal.component';
import { ViewLightZonesComponent } from './services/view-service/public-lighting/view-light-zones/view-light-zones.component';
import { ManageLightZoneModeModalComponent } from './services/view-service/public-lighting/view-light-zones/manage-light-zone-mode-modal/manage-light-zone-mode-modal.component';
import { LightZoneModeSelectorComponent } from './services/view-service/public-lighting/view-light-zones/light-zone-mode-selector/light-zone-mode-selector.component';
import { LightZonesScheduleControlComponent } from './services/view-service/public-lighting/light-zones-schedule-control/light-zones-schedule-control.component';
import { SchedulePeriodModalComponent} from './services/view-service/public-lighting/light-zones-schedule-control/schedule-period-modal/schedule-period-modal.component';
import { DaysPickerComponent } from './_shared/_components/days-picker/days-picker.component';
import { EwalletClosureScheduleComponent } from './ewallet/ewallet-closure-schedule/ewallet-closure-schedule.component';
import { EwalletListComponent } from './ewallet/ewallet-list/ewallet-list.component';
import { ScheduleEwalletClosureModalComponent } from './ewallet/ewallet-closure-schedule/schedule-ewallet-closure-modal/schedule-ewallet-closure-modal.component';
import { EwalletClosureDocumentsComponent } from './ewallet/ewallet-closure-schedule/ewallet-closure-documents/ewallet-closure-documents.component';
import {BaseChartDirective, provideCharts, withDefaultRegisterables} from 'ng2-charts';
import { BookingServiceConfigurationComponent } from './services/manage-service/booking/booking-service-configuration/booking-service-configuration.component';
import { BookingCockpitComponent } from './services/view-service/booking-cockpit/booking-cockpit.component';
import {
    FreeBookingCalendarComponent
} from './services/view-service/booking-cockpit/free-booking-calendar/free-booking-calendar.component';
import {
    ItemQuestionManagementModalComponent
} from "./services/manage-service/item/item-question-management-modal/item-question-management-modal.component";
import { FirstAndLastnamePipe } from './_shared/_components/pipe/first-and-lastname.pipe';
import { SlotsCalendarComponent } from './services/view-service/booking-cockpit/slots-calendar/slots-calendar.component';
import {BookingFormComponent} from './services/view-service/booking-cockpit/booking-form/booking-form.component';
import { UserVisitorSelectorComponent } from './_shared/_components/user-visitor-selector/user-visitor-selector.component';
import {
    BookingSlotsGenerationModalComponent
} from "./services/view-service/booking-cockpit/slots-calendar/booking-slots-generation-modal/booking-slots-generation-modal.component";
import {
    ItemWithQuestionsAnswersFormComponent
} from './services/view-service/booking-cockpit/booking-form/item-with-questions-answers-form/item-with-questions-answers-form.component';
import { BookingSlotsDeletionModalComponent } from './services/view-service/booking-cockpit/slots-calendar/booking-slots-deletion-modal-component/booking-slots-deletion-modal.component';
import {
    BookingFormConfirmationModalComponent
} from './services/view-service/booking-cockpit/booking-form/booking-form-confirmation-modal/booking-form-confirmation-modal.component';
import { OverlappingBookingModalComponent } from './services/view-service/booking-cockpit/booking-form/booking-form-confirmation-modal/overlapping-booking-modal/overlapping-booking-modal.component';
import { BookingSlotManagementModalComponent } from './services/view-service/booking-cockpit/slots-calendar/booking-slot-management-modal/booking-slot-management-modal.component';
import {
    ItemToBookSelectionComponent
} from "./_shared/_components/item-to-book-selection/item-to-book-selection.component";
import {
    BookingFormRecurringPartialCancelModalComponent
} from "./services/view-service/booking-cockpit/booking-form/booking-form-recurring-partial-cancel-modal/booking-form-recurring-partial-cancel-modal.component";
import { UserVisitorSummaryComponent } from './_shared/_components/user-visitor-summary/user-visitor-summary.component';
import { BookingViewerComponent } from './services/view-service/booking-cockpit/booking-viewer/booking-viewer.component';
import { UserCardsSummaryComponent } from './_shared/_components/user-cards-summary/user-cards-summary.component';
import { UserBoatsSummaryComponent } from './_shared/_components/user-boats-summary/user-boats-summary.component';
import {
    ChargingStationManagementComponent
} from "./services/manage-service/charging-station/charging-station-management.component";
import {
    ChargingStationManagementModalComponent
} from "./services/manage-service/charging-station/charging-stations-management-modal/charging-station-management-modal.component";
import {
    ChargingProcessManagementComponent
} from "./services/view-service/charging-process-management/charging-process-management.component";
import {
    ActivateChargingProcessModalComponent
} from "./services/view-service/activate-charging-process-modal/activate-charging-process-modal.component";
import { ChargingStationsCockpitComponent } from './services/view-service/charging-stations-cockpit/charging-stations-cockpit.component';
import {
    TariffPricesManagementComponent
} from './services/manage-service/tariff/tariff-prices-management/tariff-prices-management.component';

// Register missing locale data
registerLocaleData(localeFr);
registerLocaleData(localeDe);
registerLocaleData(localeIt);

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http, './assets/i18n/');
}

@NgModule({
    declarations: [
        AppComponent,
        ServicesComponent,
        UsersComponent,
        CashierTransactionComponent,
        ViewServiceComponent,
        ManageServiceComponent,
        AvailabilityRangeModalComponent,
        ItemModalComponent,
        TariffModalComponent,
        EwalletViewComponent,
        HasAnyRoleDirective,
        UserManagementComponent,
        HasAnyRoleDirective,
        ApplicationLoaderComponent,
        UserRfidCardModalComponent,
        HydrowashModalComponent,
        ActivateHydrowashModalComponent,
        ActivateChargingProcessModalComponent,
        ActivateServiceModalComponent,
        CguComponent,
        TransactionsComponent,
        SearchUserComponent,
        PurchaseServiceModalComponent,
        MooringPlaceComponent,
        ManageMapModalComponent,
        FreeBookingCalendarComponent,
        CalendarEventPopoverComponent,
        ViewMooringPlaceComponent,
        CalendarEventPopoverComponent,
        BookingFormComponent,
        MooringPlaceViewModalComponent,
        EditMooringPlaceComponent,
        CurrencyFormat,
        NumberFormatPipe,
        RfidCardsComponent,
        RfidCardModalComponent,
        DoorGroupModalComponent,
        SearchMeasuringPointComponent,
        AssignServiceMeasuringPointModalComponent,
        CockpitEnergyUserComponent,
        DateRangePickerComponent,
        CockpitBatteryComponent,
        CockpitEnergyStateComponent,
        CockpitEnergyConsumptionComponent,
        ConsumptionBarChartComponent,
        EnergyComponent,
        DayRangeFormat,
        AlarmComponent,
        AlarmHistoryComponent,
        AlarmDeviceManagementComponent,
        AlarmManagementComponent,
        AlarmManagementModalComponent,
        AlertComponent,
        AlertManagementComponent,
        AlertHistoryComponent,
        AlertDeviceManagementComponent,
        InfrastructureComponent,
        OfficeHourManagementComponent,
        HydrowashManagementComponent,
        ItemManagementComponent,
        ItemQuestionManagementModalComponent,
        DayRangeFormat,
        PaymentTypeChooserComponent,
        WifiManagementComponent,
        WifiModalComponent,
        WifiSubscriptionListForAdminComponent,
        WifiSubscriptionListForUserComponent,
        TariffManagementComponent,
        UserAlarmManagementComponent,
        UserAlarmModalComponent,
        UserAlarmLogsComponent,
        AlarmDeviceModalComponent,
        SearchIotRegisterComponent,
        AlertDeviceModalComponent,
        OfficeHourModalComponent,
        OfficeHourExceptionModalComponent,
        CraningServiceManagementComponent,
        CraningServiceManagementModalComponent,
        CraningServicePricingManagementModalComponent,
        CraningServiceQuestionManagementModalComponent,
        FormErrorComponent,
        CraningBookingFormComponent,
        CraningBookingComponent,
        CraningBookingSlotsGenerationModalComponent,
        BookingSlotsGenerationModalComponent,
        CraningBookingSlotsDeletionModalComponent,
        DatePeriodPickerComponent,
        CraningBookingCalendarComponent,
        CraningBookingSlotManagementModalComponent,
        ConfirmModalComponent,
        ConfirmModalTemplateDirective,
        AvailabilityRangeComponent,
        DoorGroupComponent,
        CraningBookingFormConfirmationModalComponent,
        CraningComponent,
        CraningBillingComponent,
        CraningBookingPrintComponent,
        CraningBookingFormBoatEditModalComponent,
        CraningBookingFormBillDetailsComponentComponent,
        TransactionsImportModalComponent,
        WebcamManagementComponent,
        WebcamManagementModalComponent,
        WebcamComponent,
        SortableListModalComponent,
        RoleManagementComponent,
        TransactionBillingComponent,
        TransactionCommonComponent,
        UserDeleteConfirmationModalComponent,
        UserDataFormComponent,
        DateTimePeriodPickerComponent,
        UrlTextToLinkPipe,
        BillingStateModalComponent,
        MaintenanceComponent,
        ManageEquipmentComponent,
        EquipmentEventModalComponent,
        SearchEquipmentComponent,
        MaintenanceCalendarComponent,
        DocumentUploadComponent,
        DocumentManagementComponent,
        MaintenanceContractViewComponent,
        InterventionsComponent,
        ExportPottingerStatisticModalComponent,
        InformationsComponent,
        ContactComponent,
        SurveysComponent,
        CommunicationChannelComponent,
        ManageInterventionComponent,
        SurveysManagementComponent,
        ContactManagementComponent,
        DocumentViewComponent,
        InformationsManagementComponent,
        ManageSurveyComponent,
        ViewInfoComponent,
        QrCodeViewComponent,
        MeasuringPointsCockpitComponent,
        BookingFormConfirmationModalComponent,
        BookingFormRecurringPartialCancelModalComponent,
        PushNotificationModalComponent,
        ViewSurveyComponent,
        PushNotificationModalComponent,
        ManageInformationComponent,
        WeatherCockpitComponent,
        ForgetMeComponent,
        PointOfSaleManagementComponent,
        PointOfSaleManagementModalComponent,
        KocoDumpsterManagementComponent,
        KocoDumpsterManagementModalComponent,
        ChargingStationManagementComponent,
        ChargingStationManagementModalComponent,
        JoinPipe,
        KocoDumpsterComponent,
        ActivateKocoDumpsterModalComponent,
        UserExportModalComponent,
        EwalletsComponent,
        ArrayMapPipe,
        StatisticsComponent,
        TransactionFullSearchComponent,
        ActivationManagementComponent,
        ChargingProcessManagementComponent,
        ExtendActivationModalComponent,
        ArrayMapPipe,
        CgvComponent,
        ReloadWalletModalComponent,
        RfidCardsToInlineTextPipe,
        TimeFormatPipe,
        ManageMapComponent,
        ShowCgvComponent,
        PublicLightingManagementComponent,
        PublicLightingManagementComponent,
        LightZoneManagementModalComponent,
        LightZoneManagementComponent,
        LightViewModalComponent,
        ManageMapContentComponent,
        LinkLightToExternalServiceModalComponent,
        LightZoneLightsManagementModalComponent,
        PublicLightingComponent,
        ViewLightsComponent,
        ViewLightModalComponent,
        ViewLightZonesComponent,
        ManageLightZoneModeModalComponent,
        LightZoneModeSelectorComponent,
        ViewLightZonesComponent,
        LightZonesScheduleControlComponent,
        SchedulePeriodModalComponent,
        DaysPickerComponent,
        EwalletClosureScheduleComponent,
        EwalletListComponent,
        ScheduleEwalletClosureModalComponent,
        EwalletClosureDocumentsComponent,
        BookingServiceConfigurationComponent,
        BookingCockpitComponent,
        ItemToBookSelectionComponent,
        FreeBookingCalendarComponent,
        FirstAndLastnamePipe,
        SlotsCalendarComponent,
        UserVisitorSelectorComponent,
        ItemWithQuestionsAnswersFormComponent,
        BookingSlotsDeletionModalComponent,
        OverlappingBookingModalComponent,
        BookingSlotManagementModalComponent,
        UserVisitorSummaryComponent,
        BookingViewerComponent,
        UserCardsSummaryComponent,
        UserBoatsSummaryComponent,
        ChargingStationsCockpitComponent,
        TariffPricesManagementComponent
    ],
    imports: [
        BrowserModule,
        FontAwesomeModule,
        AppRoutingModule,
        HttpClientModule,
        NgxDaterangepickerMd.forRoot(),
        MsalModule,
        FormsModule,
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            defaultLanguage: 'fr'
        }),
        ToastrModule.forRoot({
            timeOut: 10000,
            preventDuplicates: true,
            positionClass: 'toast-bottom-right',
            autoDismiss: true,
            newestOnTop: true
        }),
        LoggerModule.forRoot({level: NgxLoggerLevel.DEBUG}),
        BrowserAnimationsModule,
        NgxDatatableModule,
        NgbModule,
        ReactiveFormsModule,
        ZXingScannerModule,
        NgMultiSelectDropDownModule.forRoot(),
        FullCalendarModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireMessagingModule,
        QRCodeModule,
        NgxSortableModule,
        BaseChartDirective
    ],
    providers: [
        {
            provide: LOCALE_ID,
            useFactory: _ => {
                const lang = localStorage.getItem(AppConstants.languageKey);
                switch (lang) {
                    case 'de':
                        return 'de-DE';
                    case 'en':
                        return 'en-US';
                    case 'it':
                        return 'it-IT';
                    default:
                        return 'fr-FR';
                }
            }
        },
        {provide: ErrorHandler, useClass: GlobalErrorHandler},
        {provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true},
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        DatePipe,
        DecimalPipe,
        ConfirmModalService,
        ConfirmModalState,
        provideCharts(withDefaultRegisterables()),
    ],
    bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {
    constructor(translateUtils: TranslateUtils) {
        translateUtils.loadTranslations();
    }
}
